import styles from "./OrSeparator.module.scss";


export function OrSeparator() {
    return (
        <p className={styles.or}>
            <span className={styles.line}></span>
            <span className={styles.text}>
                Or
            </span>
            <span className={styles.line}></span>
        </p>
    );
}
