import styles from "./SocialSignInButtons.module.scss";
import { GoogleSignIn } from "@/app_components/auth/GoogleSignIn";


interface SocialSignInButtonsProps {
    redirectPathname: string | null;
}


export function SocialSignInButtons({ redirectPathname }: SocialSignInButtonsProps) {
    return (
        <div className={styles.container}>
            <GoogleSignIn redirectPathname={redirectPathname} />
        </div>
    );
}
