import styles from "./SocialSignIn.module.scss";
import Link from "next/link";
import { ReactNode } from "react";


interface SocialSignInProps {
    className?: string;
    provider: string;
    redirectPathname: string | null;
    children: ReactNode;
}


export function SocialSignIn({
    className, provider, redirectPathname, children,
}: SocialSignInProps) {
    const urlParams = new URLSearchParams();
    urlParams.set("provider", provider);
    if (redirectPathname) {
        urlParams.set("redirect", redirectPathname);
    }
    return (
        <Link
            className={`${styles.box} ${className}`}
            prefetch={false}
            href={`/login/rosette?${urlParams.toString()}`}>

            {children}
        </Link>
    );
}
