import styles from "./PasswordInput.module.scss";
import { useState } from "react";
import VisibilityIcon from "@/app_components/icon/VisibilityIcon";
import VisibilityOffIcon from "@/app_components/icon/VisibilityOffIcon";


interface PasswordInputProps {
    id: string;
    name?: string;
    value: string;
    setValue: (value: string) => void;
    autoComplete?: string;
    required?: boolean;
}

export function PasswordInput({
    id, name, value, setValue, autoComplete, required,
}: PasswordInputProps) {
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);
    return (
        <div className={`${styles.container} input-container`}>
            <input
                className={styles.input}
                type={showPassword ? "text" : "password"}
                id={id}
                name={name}
                value={value}
                onChange={e => setValue(e.target.value)}
                autoComplete={autoComplete}
                required={required} />

            <button
                className={styles.visibility_toggle}
                type="button"
                role="switch"
                aria-checked={showPassword}
                onClick={togglePassword}>

                {showPassword && <VisibilityOffIcon />}
                {!showPassword && <VisibilityIcon />}

                <span className="sr-only">
                    {showPassword ? "Hide password" : "Show password"}
                </span>
            </button>
        </div>
    );
}
