import styles from "./RosetteGamesBanner.module.scss";
import Image from "next/image";
import logo from "@/res/rosettegames/logo.png";
import Link from "next/link";
import { ROSETTE_URL } from "@/ts/business/constants";
import { cn } from "@/ts/util/cn";


interface RosetteGamesBannerProps {
    className?: string;
    darkMode?: boolean;
}


export function RosetteGamesBanner({ className, darkMode }: RosetteGamesBannerProps) {
    const darkModeClass = (darkMode ? styles.dark_mode : "");
    return (
        <div className={cn(styles.banner, darkModeClass, className)}>
            <Link href={ROSETTE_URL}>
                <div className={styles.left}>
                    <Image className={styles.logo} src={logo} alt="Rosette Games Logo" />
                </div>
                <div className={styles.right}>
                    Rosette
                    <br />
                    Games
                </div>
            </Link>
        </div>
    );
}
