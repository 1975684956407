"use client";

import styles from "./RosetteForm.module.scss";
import { Form, FormProps, FormStatus } from "@/app_components/form/Form";
import { RosetteGamesBanner } from "@/app_components/social/banner/RosetteGamesBanner";
import React, { ReactNode } from "react";
import { useFormState } from "@/app_components/form/FormStateContext";
import { RosetteCard } from "@/app_components/generic/card/themed/RosetteCard";
import ArticleDir, { ArticleDirPiece } from "@/app_components/articles/ArticleDir";


interface RosetteSubmitButtonProps {
    label?: string;
}


export function RosetteSubmitButton({ label }: RosetteSubmitButtonProps) {
    const formState = useFormState();
    return (
        <>
            <button
                type="submit"
                disabled={!!(formState.isPending || formState.validationError)}
                title={formState.isPending ? "Processing..." : formState.validationError}>

                {label ?? "Submit"}
            </button>

            <FormStatus />
        </>
    );
}


interface RosetteTitleSectionProps {
    title: string;
}


export function RosetteTitleSection({ title }: RosetteTitleSectionProps) {
    return (
        <div className={styles.title_section}>
            <RosetteGamesBanner />

            {title && (
                <h1>
                    {title}
                </h1>
            )}
        </div>
    );
}


interface RosetteFormContentProps {
    children: ReactNode;
    title: string;
}


function RosetteFormContent({ children, title }: RosetteFormContentProps) {
    return (
        <>
            <RosetteTitleSection title={title} />

            {children}
        </>
    );
}


export interface RosetteFormProps extends FormProps {
    children?: ReactNode;
    title: string;
    backPieces?: ArticleDirPiece[];
}


export function RosetteForm({
    children, title, backPieces,
    ...props
}: RosetteFormProps) {

    return (
        <>
            {backPieces && (
                <div className={styles.back}>
                    <ArticleDir dir={backPieces} />
                </div>
            )}
            <RosetteCard className={styles.card}>
                <Form {...props}>
                    <RosetteFormContent title={title}>
                        {children}
                    </RosetteFormContent>
                </Form>
            </RosetteCard>
        </>
    );
}
