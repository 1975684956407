"use client";

import styles from "./AccountMenuButton.module.scss";
import React, { useState } from "react";
import { AccountMenu } from "@/app_components/account/AccountMenu";
import { AccountIcon } from "@/app_components/icon/AccountIcon";
import { useUserOrIncompleteUser } from "@/app_components/user/UserContext";
import Link from "next/link";
import { PopupButton } from "@/app_components/generic/popup/PopupButton";
import { cn } from "@/ts/util/cn";
import { usePathname } from "next/navigation";
import { Path } from "@/ts/util/Path";
import { LoginFormModal } from "@/app_components/auth/LoginForm";


interface AccountMenuProps {
    className?: string;
}


export function AccountMenuButton({ className }: AccountMenuProps) {
    const { incompleteUser, user } = useUserOrIncompleteUser();
    const pathname = usePathname();

    const [showLogin, setShowLogin] = useState(false);
    if (incompleteUser) {
        return (
            <Link
                className={cn(
                    styles.button,
                    className,
                )}
                href="/setup">

                <span>
                    Set Up
                </span>
                <AccountIcon />
            </Link>
        );
    }
    if (!user) {
        return (
            <>
                <Link
                    className={cn(
                        styles.button,
                        className,
                    )}
                    href={new Path("/login", { redirect: pathname }).toString()}
                    onClick={(event) => {
                        if (!showLogin) {
                            event.preventDefault();
                            setShowLogin(true);
                        }
                    }}>

                    <span>
                        Sign In
                    </span>
                    <AccountIcon />
                </Link>

                <LoginFormModal
                    visible={showLogin}
                    close={() => setShowLogin(false)} />
            </>
        );
    }

    return (
        <PopupButton
            buttonClassName={cn(className, styles.button)}
            contentClassName={styles.menu}
            darkMode={true}
            minPopupWidthPx={240}
            buttonContent={(
                <>
                    <span>
                        {user.displayName}
                    </span>
                    <AccountIcon />

                    <span className="sr-only">
                        Open Account Menu
                    </span>
                </>
            )}>

            <AccountMenu user={user} />
        </PopupButton>
    );
}
