import styles from "./ArticleDir.module.scss";
import React from "react";
import Link from "next/link";
import { cn } from "@/ts/util/cn";
import BackArrow from "@/app_components/icon/BackArrow";


export interface ArticleDirPiece {
    label: string;
    href: string;
}


interface ArticleDirProps {
    className?: string;
    dir: ArticleDirPiece[];
}


export default function ArticleDir({
    className, dir,
}: ArticleDirProps) {
    return (
        <div className={cn(
            styles.dir,
            className,
        )}>
            {dir.map((piece, index) => (
                <div
                    className={styles.dir_piece_container}
                    key={`p${index}`}>

                    {index > 0 && (
                        <>
                            &nbsp;/&nbsp;
                        </>
                    )}

                    <Link
                        className={styles.dir_piece}
                        href={piece.href}>

                        {dir.length === 1 && (
                            <BackArrow />
                        )}
                        <span>
                            {piece.label}
                        </span>
                    </Link>
                </div>
            ))}
        </div>
    );
}
