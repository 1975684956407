import styles from "./GoogleSignIn.module.scss";
import { SocialSignIn } from "@/app_components/auth/SocialSignIn";
import logo from "@/res/icons/google.svg";
import Image from "next/image";


interface GoogleSignInProps {
    redirectPathname: string | null;
}


export function GoogleSignIn({ redirectPathname }: GoogleSignInProps) {
    return (
        <SocialSignIn className={styles.button} provider="Google" redirectPathname={redirectPathname}>
            <Image src={logo} alt="Google Logo" />
            <span>
                Continue with Google
            </span>
        </SocialSignIn>
    );
}
