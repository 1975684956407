"use client";
import styles from "./Header.module.scss";
import logoImage from "@/res/logo_text_only.svg";
import Image from "next/image";
import Link from "next/link";
import { cn } from "@/ts/util/cn";
import { AccountMenuButton } from "@/app_components/account/AccountMenuButton";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";


interface HeaderLinkProps {
    href: string;
    pathname: string;
    children?: ReactNode;
}


function HeaderLink({ href, pathname, children }: HeaderLinkProps) {
    return (
        <Link
            className={cn(
                "link",
                (href === pathname ? "active_link" : null),
                styles.header_link,
                (href === pathname ? styles.active_header_link : null),
            )}
            href={href}>

            {children}
        </Link>
    );
}


interface HeaderProps {
    className?: string;
}

export default function Header({ className }: HeaderProps) {
    const pathname = usePathname();
    return (
        <header className={cn(styles.header_wrapper, className)}>
            <div className={styles.header}>
                <Link className={styles.logo} href="/">
                    <Image src={logoImage} alt="Logo of RoyalUr.net" />
                </Link>
                <div className={styles.links}>
                    <HeaderLink pathname={pathname} href="/">Home</HeaderLink>
                    <HeaderLink pathname={pathname} href="/rules">Rules</HeaderLink>
                    <HeaderLink pathname={pathname} href="/about">About</HeaderLink>
                    <HeaderLink pathname={pathname} href="/blog">Blog</HeaderLink>
                </div>
                <div className={styles.account}>
                    <AccountMenuButton />
                </div>
            </div>
        </header>
    );
}
