import styles from "./Card.module.scss";
import { ReactNode } from "react";
import { cn } from "@/ts/util/cn";


interface CardProps {
    children: ReactNode;
    className?: string;
}


export function Card({ children, className }: CardProps) {
    return (
        <div className={cn(
            styles.card,
            className,
        )}>
            {children}
        </div>
    );
}
