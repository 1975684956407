import styles from "./Modal.module.scss";
import React, { ReactNode, useEffect, useRef } from "react";
import { PopupBase } from "@/app_components/generic/popup/PopupBase";
import { cn } from "@/ts/util/cn";


interface ModalProps {
    children?: ReactNode;
    visible: boolean;
    close: () => void;

    containerClassName?: string;
    contentsClassName?: string;
    fixed?: boolean;
}


export function Modal({
    children, visible, close,
    containerClassName, contentsClassName, fixed,
}: ModalProps) {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const contentsRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!fixed || !visible)
            return;

        const pageScrollY = window.scrollY;
        document.body.style.position = "fixed";
        document.body.style.top = `-${pageScrollY}px`;
        return () => {
            document.body.style.position = "";
            document.body.style.top = "";
            window.scrollTo(0, pageScrollY);
        };
    }, [fixed, visible]);

    return (
        <PopupBase
            visible={visible}
            close={close}
            contentsRef={contentsRef}>

            <div
                className={cn(
                    containerClassName,
                    styles.container,
                    fixed && styles.fixed,
                )}
                ref={containerRef}>

                <div
                    className={cn(
                        contentsClassName,
                        styles.contents,
                    )}
                    ref={contentsRef}>

                    {children}
                </div>
            </div>
        </PopupBase>
    );
}
