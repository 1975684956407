import styles from "./LoginForm.module.scss";
import React, { useState } from "react";
import { RosetteForm, RosetteSubmitButton } from "@/app_components/form/themed/RosetteForm";
import { OrSeparator } from "@/app_components/generic/OrSeparator";
import { SocialSignInButtons } from "@/app_components/auth/SocialSignInButtons";
import Link from "next/link";
import { PasswordInput } from "@/app_components/form/input/PasswordInput";
import { useSearchParams } from "next/navigation";
import { Modal } from "@/app_components/generic/popup/Modal";
import { ArticleDirPiece } from "@/app_components/articles/ArticleDir";
import { useWindow } from "@/app_util/ssrHelper";


interface LoginFormProps {
    backPieces?: ArticleDirPiece[];
}


export function LoginForm({ backPieces }: LoginFormProps) {
    const searchParams = useSearchParams();
    let redirectPathname = searchParams.get("redirect");

    const theWindow = useWindow();
    if (redirectPathname === null && theWindow) {
        // Used for login modal.
        if (theWindow.location.pathname !== "/login") {
            redirectPathname = theWindow.location.pathname + theWindow.location.search;
        }
    }

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const emailError = (email.length === 0 ? "Please provide an email" : null);
    const passwordError = (password.length === 0 ? "Please provide a password" : null);

    const validationError = emailError ?? passwordError;

    return (
        <RosetteForm
            title="Sign In to RoyalUr.net"
            className={styles.form}
            action="/login/password"
            validationError={validationError}
            backPieces={backPieces}>

            <SocialSignInButtons redirectPathname={redirectPathname} />
            <OrSeparator />

            <label htmlFor="email">Email</label>
            <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required={true} />

            <label htmlFor="password">Password</label>
            <PasswordInput
                id="password"
                name="password"
                value={password}
                setValue={setPassword}
                required={true} />

            {redirectPathname && (
                <input
                    type="hidden"
                    id="redirectPathname"
                    name="redirectPathname"
                    value={redirectPathname} />
            )}

            <p className={styles.forgot_password}>
                <Link href="/recover">
                    Forgot password?
                </Link>
            </p>

            <RosetteSubmitButton label="Sign In" />

            <p>
                Don&apos;t have an account?
                {" "}
                <Link href="/register">
                    Register here.
                </Link>
            </p>
        </RosetteForm>
    );
}


interface LoginFormModalProps {
    visible: boolean;
    close: () => void;
}


export function LoginFormModal({ visible, close }: LoginFormModalProps) {
    return (
        <Modal
            containerClassName={styles.login_popup}
            contentsClassName={styles.login_popup_content}
            fixed={true}
            visible={visible}
            close={close}>

            <LoginForm />
        </Modal>
    );
}
