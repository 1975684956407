import styles from "./RosetteCard.module.scss";
import { ReactNode } from "react";
import { Card } from "@/app_components/generic/card/Card";
import { cn } from "@/ts/util/cn";
import Link from "next/link";


interface RosetteLinkButtonProps {
    children?: ReactNode;
    href: string;
}


export function RosetteLinkButton({ children, href }: RosetteLinkButtonProps) {
    return (
        <Link className={cn(styles.button, "button-like")} href={href}>
            {children}
        </Link>
    );
}


interface RosetteCardProps {
    children: ReactNode;
    className?: string;
}


export function RosetteCard({ children, className }: RosetteCardProps) {
    return (
        <Card className={cn(className, styles.card)}>
            {children}
        </Card>
    );
}
