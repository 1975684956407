"use client";
import { useOptionalUser } from "@/app_components/user/UserContext";
import Link from "next/link";


interface FooterProfileLinkProps {
    className?: string;
}


export function FooterProfileLink({ className }: FooterProfileLinkProps) {
    const user = useOptionalUser();
    if (!user) {
        return (
            <Link className={className} href="/register">
                Register
            </Link>
        );
    }
    return (
        <Link className={className} href={`/u/${user.username}`}>
            Profile
        </Link>
    );
}
