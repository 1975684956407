import styles from "./MemburIcon.module.scss";
import crownIcon from "@/res/crown.svg";
import { Role } from "@/ts/business/role/Role";
import Image from "next/image";
import { cn } from "@/ts/util/cn";

interface MemburIconProps {
    className?: string;
    title?: string;
}

export default function MemburIcon({ className, title }: MemburIconProps) {
    return (
        <Image
            className={cn(styles.icon, className)}
            src={crownIcon}
            alt={Role.MEMBUR.getDesc()}
            title={title} />
    );
}
