import styles from "./AccountMenu.module.scss";
import React from "react";
import Link from "next/link";
import { cn } from "@/ts/util/cn";
import Image from "next/image";
import { getRoleIcon } from "@/app_components/icon/getRoleIcon";
import { Role } from "@/ts/business/role/Role";
import rosetteIcon from "@/res/icons/rosette.png";
import MemburIcon from "@/app_components/icon/MemburIcon";
import { APIUser } from "@/ts/business/api/api_schema";


interface AccountMenuProps {
    className?: string;
    user: APIUser;
}


export function AccountMenu({ className, user }: AccountMenuProps) {
    return (
        <div className={cn(className, styles.menu)}>
            <Link href={`/u/${user.username}`}>
                <Image
                    src={rosetteIcon}
                    alt="The RoyalUr.net rosette icon, in blue and red" />

                <span>
                    Profile
                </span>
            </Link>

            <Link className={styles.memburship} href="/memburship">
                <MemburIcon />
                <span>
                    Memburship
                </span>
            </Link>

            <Link href="/settings">
                <Image
                    src={getRoleIcon(Role.STANDARD)}
                    alt={Role.STANDARD.getDesc()} />

                <span>
                    Settings
                </span>
            </Link>

            {user.hasRoleAdmin && (
                <Link href="/admin">
                    <Image
                        src={getRoleIcon(Role.ADMIN)}
                        alt={Role.ADMIN.getDesc()} />

                    <span>
                        Admin
                    </span>
                </Link>
            )}

            <Link className={styles.logout} href="/logout">
                Sign out
            </Link>
        </div>
    );
}
